import React from 'react';

const ReactGatsby = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3332 3.91406C12.0574 3.91406 2.9165 13.0549 2.9165 24.3307C2.9165 35.6066 12.0574 44.7474 23.3332 44.7474C34.6091 44.7474 43.7498 35.6066 43.7498 24.3307C43.7498 13.0549 34.6091 3.91406 23.3332 3.91406ZM7.30353 24.5436L23.1203 40.3604C14.4351 40.2472 7.4167 33.2288 7.30353 24.5436ZM26.9214 39.9596L7.70432 20.7425C9.33385 13.6157 15.7124 8.29841 23.3332 8.29841C28.6602 8.29841 33.3796 10.8972 36.295 14.8954L34.0752 16.8541C31.7103 13.4626 27.7812 11.2431 23.3332 11.2431C17.6699 11.2431 12.8472 14.8404 11.0243 19.8744L27.7895 36.6396C31.8609 35.1652 34.9922 31.7284 36.0409 27.4718H29.0917V24.3308H36.4208V24.3307H39.3655V24.3307C39.3655 31.9515 34.0482 38.3301 26.9214 39.9596Z"
      fill="#744C9E"
    />
  </svg>
);

export default ReactGatsby;
